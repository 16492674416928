import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import slugify from "slugify";
// import Seo from "../components/seo";
import ArticlePreview from "../components/ArticlePreview";
import { ServicesSlider } from "../components/ServicesSlider";
import { Advantages } from "../components/Advantages";
import { AboutUs } from "../components/AboutUs";
import { Logos } from "../components/Logos";
import { Link } from "gatsby";
import ContactForm from "../components/contact";
import { Helmet } from "react-helmet";
import { HandleSlider } from "../components/HandleSlider";

const ArticlesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
`;

const handleMoveFooter = () => {
  document.getElementById("footerId")?.scrollIntoView({ behavior: "smooth" });
};

const handleMoveAbUs = () => {
  document.getElementById("about")?.scrollIntoView({ behavior: "smooth" });
};

const advElements = [
  {
    contentTitle: "Projektujemy",
    contentTxt:
      "Tworzymy niestandardowe rozwiązania informatyczne, które usprawnią procesy w Twojej firmie i zwiększą jej konkurencyjność.",
    imgSrc: require("../assets/images/advantages/projektujemy.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Doradzamy",
    contentTxt:
      "Nasza wiedza i doświadczenie są do Twojej dyspozycji. Doradzimy Ci, jakie technologie mogą przynieść najlepsze rezultaty dla Twojego biznesu.",
    imgSrc: require("../assets/images/advantages/doradzamy.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Wdrażamy",
    contentTxt:
      "Posiadamy zespół ekspertów w swojej dziedzinie, którzy łączą swoją wiedzę i kreatywność, aby dostarczyć najlepsze rozwiązania.",
    imgSrc: require("../assets/images/advantages/wdrazamy.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Szkolimy",
    contentTxt:
      "Nasza opieka nie kończy się po dostarczeniu rozwiązania. Zapewniamy pełne wsparcie, aby Twoje rozwiązanie działało sprawnie.",
    imgSrc: require("../assets/images/advantages/szkolimy.svg").default,
    alt: "Opis obrazka",
  },
  {
    contentTitle: "Serwisujemy",
    contentTxt:
      "Nie tylko projektujemy nowoczesne rozwiązania informatyczne, ale również dbamy o ich niezawodność i efektywność.",
    imgSrc: require("../assets/images/advantages/serwis.svg").default,
    alt: "Opis obrazka",
  },
];

let slidesContent = "";

slidesContent = [
  {
    box: (
      <>
        <StaticImage
          style={{ paddingTop: "200px" }}
          className="desktopImg"
          src="../assets/images/main-slider/front-heroe2.png"
          alt=""
        />
        <div className="heroe-txt">
          <h1>Nowoczesne rozwiązania </h1>
          <p>dla biznesu</p>
        </div>
        <div className="btn-container">
          <div className="btn-container-wrapper">
            <button
              onClick={() => handleMoveAbUs()}
              style={{
                color: slidesContent.color,
                borderColor: slidesContent.color,
              }}
            >
              Poznaj nas
            </button>
            <button onClick={() => handleMoveFooter()}>Skontaktuj się</button>
          </div>
        </div>
      </>
    ),
  },
  {
    box: (
      <>
        <StaticImage
          className="desktopImg"
          src="../assets/images/main-slider/front-heroe3.jpg"
          alt=""
        />
        <div className="heroe-txt">
          <h1>Elektronika użytkowa</h1>
          <p>dla Ciebie</p>
        </div>
        <div className="btn-container">
          <div className="btn-container-wrapper">
            <button
              onClick={() => handleMoveAbUs()}
              style={{
                color: slidesContent.color,
                borderColor: slidesContent.color,
              }}
            >
              Poznaj nas
            </button>
            <button onClick={() => handleMoveFooter()}>Skontaktuj się</button>
          </div>
        </div>
      </>
    ),
  },
];

const IndexPage = ({ data }) => {
  const {
    allDatoCmsArticle: { nodes },
  } = data;

  // Sort articles by date in descending order
  const sortedArticles = nodes.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  const [color, setColor] = useState("#b52f3b");
  const [scrolled, setScrolled] = useState("normal");

  const handleMoveDown = () => {
    document.getElementById("services")?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 900) {
        setScrolled("active");
      } else {
        setScrolled("normal");
      }
    };
    window.addEventListener("scroll", handleScroll);

    setTimeout(() => {
      setColor((color) => color === "white");
    }, 300);
  }, []);

  return (
    <Layout>
      <div className="main-page">
        <section className="heroe">
          <div className="heroe-container">
            <div
              id="mainSliderSpecial"
              className={`elements-list  ${scrolled}`}
            >
              <HandleSlider slidesContent={slidesContent} />
              {/* <StaticImage
                className={`desktopImg ${scrolled}`}
                src="../assets/images/front-heroe.jpg"
                alt=""
              /> */}
            </div>

            <button onClick={() => handleMoveDown()} className="arrowBottom">
              {">>"}
            </button>
          </div>
        </section>
        <section className="services">
          <div id="services" className="anchor" />
          <ServicesSlider />
        </section>
        <section className="advantages">
          <Advantages
            headerTitle={"Jak działamy"}
            headerSubtitle={
              <span>
                Czym się zajmujemy <br /> <strong>dla Twojego Biznesu</strong>
              </span>
            }
            headerTxt={
              "W JB Multimedia specjalizujemy się w dostarczaniu innowacyjnych rozwiązań technologicznych, które przekształcają Twoje pomysły w sukces biznesowy. Jesteśmy gotowi być Twoim Partnerem w osiąganiu celów i wyznaczaniu nowych standardów"
            }
            advantageElements={advElements}
          />
        </section>
        <section className="aboutUs aboutUsPage">
          <AboutUs />
        </section>
        <section className="news">
          <div className="news-container">
            <div className="news txt">
              <div className="news txt-container">
                <h4>Aktualności</h4>
                <h2>
                  Zobacz co <br /> <strong>nowego u nas</strong>
                </h2>
              </div>
              <p className="headerTxt">
                Miejsce, gdzie dzielimy się informacjami, inspiracjami i
                nowościami związanymi z naszą firmą oraz branżą, w której
                działamy.
              </p>
            </div>
            <div className="news-container-wrapper">
              <ArticlesWrapper className="news-container-wrapper-elements">
                {sortedArticles
                  .map(({ title, featuredImage, date, articleContent }) => (
                    <ArticlePreview
                      key={title}
                      title={title}
                      date={date}
                      articleContent={[
                        articleContent.map((el) => {
                          return el.headingContent;
                        }),
                      ]}
                      image={featuredImage.fluid}
                      slug={slugify(title, { lower: true })}
                    />
                  ))
                  .slice(0, 3)}
              </ArticlesWrapper>
            </div>
          </div>
        </section>
        <section className="logos">
          <Logos />
        </section>
      </div>
      <div className="footer-container-wrapper-form fullImg mainPage">
        <div className="left-col">
          {" "}
          <StaticImage
            style={{ zIndex: "1" }}
            className="desktopImg"
            src="../assets/images/footer.jpg"
          />
        </div>
        <div className="footer-txt-container">
          <Link to="/kontakt">
            <h2 className="txtSpecial">
              {" "}
              Porozmawiajmy o <b>Twoich potrzebach</b>
            </h2>
          </Link>
          <span>
            Cieszymy się, że jesteś zainteresowany współpracą z nami. Chętnie
            odpowiemy na Twoje pytania, udzielimy informacji lub omówimy Twoje
            potrzeby biznesowe.
          </span>
          <ContactForm />
        </div>
      </div>
      <Helmet>
        <meta charset="utf-8" />
        <title>Firma JB Multimedia Biała Podlaska Laptopy, Drukarki, AGD</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
    </Layout>
  );
};

export const queryNews = graphql`
  {
    allDatoCmsArticle {
      nodes {
        title
        date
        articleContent {
          ... on DatoCmsHeading {
            id
            headingContent
          }
        }
        featuredImage {
          fluid(maxWidth: 500) {
            ...GatsbyDatoCmsFluid_tracedSVG
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//   query {
//     file(name: { eq: "office" }) {
//       childImageSharp {
//         gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
//       }
//     }
//   }
// `;

export default IndexPage;
