import React, { useRef, useState, useEffect } from "react";

export const HandleSlider = ({ slidesContent }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [moveSlide, setMoveSlide] = useState(0);
  const elementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [color, setColor] = useState("#b52f3b");

  const handleNextSlide = () => {
    setMoveSlide((prevSlide) => {
      if (window.innerWidth >= 768) {
        if (window.innerWidth <= 1649) {
          if (window.innerWidth <= 1349) {
            if (window.innerWidth <= 1023) {
              if (
                prevSlide >=
                -(elementWidth + 50) *
                  (slidesContent.length -
                    (elementWidth === 400 ? 2 : elementWidth > 400 ? 2 : 4))
              ) {
                return prevSlide - (elementWidth + 50);
              } else {
                setMoveSlide(0);
              }
            } else {
              if (
                prevSlide >=
                -(elementWidth + 50) *
                  (slidesContent.length -
                    (elementWidth === 400 ? 3 : elementWidth > 400 ? 2 : 5))
              ) {
                return prevSlide - (elementWidth + 50);
              } else {
                setMoveSlide(0);
              }
            }
          } else {
            if (
              prevSlide >=
              -(elementWidth + 50) *
                (slidesContent.length -
                  (elementWidth === 400 ? 3 : elementWidth > 400 ? 2 : 6))
            ) {
              return prevSlide - (elementWidth + 50);
            } else {
              setMoveSlide(0);
            }
          }
        } else {
          if (
            prevSlide >=
            -(elementWidth + 50) *
              (slidesContent.length -
                (elementWidth === 400 ? 4 : elementWidth > 400 ? 2 : 6))
          ) {
            return prevSlide - (elementWidth + 50);
          } else {
            setMoveSlide(0);
          }
        }
      } else {
        if (prevSlide >= -(elementWidth + 50) * (slidesContent.length - 2)) {
          return prevSlide - (elementWidth + 50);
        } else {
          setMoveSlide(0);
        }
      }
    });
  };

  const handlePrevSlide = () => {
    setMoveSlide((prevSlide) => {
      if (prevSlide < -(elementWidth - 50)) {
        return prevSlide + (elementWidth + 50);
      } else {
        if (window.innerWidth >= 768) {
          setMoveSlide(
            -(elementWidth + 50) *
              (slidesContent.length -
                (elementWidth === 400 ? 3 : elementWidth > 400 ? 1 : 5))
          );
          if (window.innerWidth <= 1649) {
            if (window.innerWidth <= 1349) {
              setMoveSlide(
                -(elementWidth + 50) *
                  (slidesContent.length - (elementWidth === 400 ? 3 : 5))
              );
              if (window.innerWidth <= 1023) {
                setMoveSlide(
                  -(elementWidth + 50) *
                    (slidesContent.length -
                      (elementWidth === 400 ? 1 : elementWidth > 400 ? 1 : 3))
                );
              } else {
                setMoveSlide(
                  -(elementWidth + 50) *
                    (slidesContent.length -
                      (elementWidth === 400 ? 2 : elementWidth > 400 ? 1 : 4))
                );
              }
            } else {
              setMoveSlide(
                -(elementWidth + 50) *
                  (slidesContent.length -
                    (elementWidth === 400 ? 2 : elementWidth > 400 ? 1 : 5))
              );
            }
          }
        } else {
          setMoveSlide(-(elementWidth + 50) * (slidesContent.length - 1));
        }
      }
    });
  };

  const startInterval = () => {};

  useEffect(() => {
    const newWidth = window.innerWidth;
    if (elementRef.current) {
      const width = elementRef?.current?.offsetWidth;
      setElementWidth(width);
    }
    const updateWidnowDimesions = () => {
      setWindowWidth(newWidth);

      if (window.innerWidth !== newWidth) {
        setMoveSlide(0);
      }

      const width = elementRef?.current?.offsetWidth;
      setElementWidth(width);
    };
    window.addEventListener("resize", updateWidnowDimesions);

    const startInterval = setInterval(() => {
      handleNextSlide();
    }, 7000);

    return () => clearInterval(startInterval);
  }, [startInterval]);

  return (
    <div id="handle-slider" className="elements-list">
      <div className="slider-slides-cnt" aria-live="polite" aria-atomic="true">
        {slidesContent.map((slideContent, index) => (
          <article
            key={index}
            className={`element slider-slide ${
              index === currentSlide ? "slider-slide-active" : ""
            }`}
            style={{ transform: `translateX(${moveSlide}px)` }}
            ref={elementRef}
            aria-roledescription="slide"
            aria-label={`${index + 1} z ${slidesContent.length}`}
            role="group"
          >
            <div className="desktopImg-container">{slideContent.imgBox}</div>
            <div className="handle-sliderSlider-txt-container">
              <div className="qual-sign-box">{slideContent.box}</div>
              <h2>{slideContent.title}</h2>
              <span>{slideContent.subtitle}</span>
              <div className="features-wrapper">
                <div className="features-wrapper-left">
                  {slideContent.leftCol}
                </div>
                <div className="features-wrapper-right">
                  {slideContent.rightCol}
                </div>
              </div>
              <p>{slideContent.text}</p>
            </div>
          </article>
        ))}
      </div>
      <div className="slider-nav">
        <button
          onClick={handlePrevSlide}
          className="slider-button slider-button-prev"
          aria-label="Poprzedni slajd"
        >
          <span className="visually-hidden">Poprzedni slajd</span>
        </button>
        <button
          onClick={handleNextSlide}
          className="slider-button slider-button-next"
          aria-label="Następny slajd"
        >
          <span className="visually-hidden">Następny slajd</span>
        </button>
      </div>
    </div>
  );
};
