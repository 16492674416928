import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const ServicesSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [serviceBg, setServiceBg] = useState(null);

  const slides = [
    {
      title: "Dla Twojego Biznesu",
      link: "/biznes-instytucja",
      subtitle: "dla Twojego biznesu",
      p1: "JB Multimedia to Partner, który dostarcza nowoczesne rozwiązania informatyczne dla Twojej firmy.",
      p2: "Oferujemy kompleksowe narzędzia i usługi, które zapewniają nie tylko szybki i niezawodny dostęp do internetu, ale także optymalną organizację sieci w Twojej firmie. Dzięki naszym rozwiązaniom, zarządzanie danymi, udostępnianie zasobów oraz komunikacja między pracownikami stają się bardziej efektywne i bezpieczne. ",
      p3: (
        <>
          Nasze usługi obejmują dostosowane do Twoich potrzeb narzędzia
          informatyczne, analizy danych, doradztwo w wyborze optymalnych
          rozwiązań i wiele więcej. Wybierając naszą ofertę, otwierasz drzwi do
          nowych <strong>możliwości i efektywności w biznesie</strong>.
        </>
      ),

      imgSrc: require("../assets/images/services/biznes.webp").default,
    },
    {
      title: "Nowoczesna edukacja",
      link: "/nowoczesna-edukacja",
      subtitle: "to klucz do efektywnej nauki",
      p1: "Nasza oferta dla nowoczesnej edukacji to klucz do przyszłości nauki. Oferujemy innowacyjne rozwiązania technologiczne, interaktywne narzędzia dydaktyczne oraz wsparcie dla placówek oświatowych.",
      p2: "Dzięki naszym rozwiązaniom, nauczyciele mogą tworzyć inspirujące lekcje, uczniowie mogą uczyć się w sposób angażujący, a zarządzanie placówką staje się efektywne i zgodne z wymaganiami współczesnej edukacji.",
      p3: (
        <>
          Wybierając naszą ofertę, otwierasz drzwi
          <strong> do nowoczesnej i efektywnej nauki</strong>.
        </>
      ),

      imgSrc: require("../assets/images/services/szkola.webp").default,
    },
    {
      title: "Podpis Kwalifikowany",
      link: "/podpis-kwalifikowany",
      subtitle: "bezpieczne rozwiązania dla Twojej Firmy",
      p1: (
        <>
          Kwalifikowany podpis elektroniczny to klucz do usprawnienia
          komunikacji z administracją publiczną oraz biznesem. Za jego pomocą
          podpiszesz <strong>deklaracje ZUS</strong>, generujesz{" "}
          <strong>Jednolity Plik Kontrolny</strong>, a także wysyłasz
          sprawozdania finansowe przez platformę <strong>e-KRS</strong>.
        </>
      ),
      p2: (
        <>
          To rozwiązanie, które jest nie tylko <strong>wygodne i pewne</strong>,
          ale również w pełni bezpieczne, oszczędzając Twój czas i pieniądze.
          Dzięki niemu podpiszesz różne rodzaje dokumentów, w tym faktury
          elektroniczne, pisma procesowe w sądowym postępowaniu upominawczym,
          e-deklaracje podatkowe i wiele więcej.
        </>
      ),
      p3: (
        <>
          Zaufaj kwalifikowanemu podpisowi elektronicznemu i
          <strong>
            {" "}
            zyskaj kontrolę nad swoimi dokumentami oraz transakcjami online
          </strong>
          .
        </>
      ),

      imgSrc: require("../assets/images/services/podpis.webp").default,
    },
    {
      title: "Projektowanie Stron Internetowych",
      link: "/projektowanie-stron",
      subtitle: "by wyróżnić Twój Biznes",
      p1: "Specjalizujemy się w tworzeniu nowoczesnych i dynamicznych stron internetowych, wykorzystując technologię React.js. Nasze rozwiązania to połączenie estetyki, funkcjonalności i wydajności, które przyciągają uwagę użytkowników i pozwalają osiągnąć cele biznesowe.",
      p2: (
        <>
          Jeśli szukasz{" "}
          <strong>profesjonalnego i nowoczesnego podejścia</strong> do
          projektowania stron internetowych, jesteśmy gotowi pomóc Ci stworzyć
          stronę, która przyciągnie uwagę i{" "}
          <strong>przekształci odwiedzających w klientów.</strong>
        </>
      ),

      imgSrc: require("../assets/images/services/projektowanie.webp").default,
    },
    {
      title: "Kasy fiskalne On-line",
      link: "/kasy-fiskalne-online",
      subtitle: "dla Twojego biznesu",
      p1: (
        <>
          Kasy online to specjalne urządzenia fiskalne, które nawiązują stały
          kontakt z <strong>Centralnym Repozytorium Kas (CRK)</strong>, będącym
          prowadzonym przez Szefa Krajowej Administracji Skarbowej. Urządzenia
          te regularnie przesyłają wymagane informacje zgodnie z harmonogramem
          określonym przez Repozytorium.
        </>
      ),

      imgSrc: require("../assets/images/services/registers.webp").default,
    },
    {
      title: "Dzierżawa drukarek",
      link: "/dzierzawa-druku",
      subtitle: "Co warto wiedzieć?",
      p1: (
        <>
          <b>Outsourcing druku</b> jest popularną strategią wśród firm, które
          chcą skoncentrować się na swojej głównej działalności, oszczędzać czas
          i pieniądze oraz korzystać z wysokiej jakości usług drukarskich. Jest
          to również <strong>elastyczne rozwiązanie,</strong> które pozwala
          firmom dostosować się do zmieniających się potrzeb biznesowych.
        </>
      ),
      p2: (
        <>
          Nasza oferta obejmuje urządzenia <b>Ricoh i Epson,</b> które spełnią
          oczekiwania nawet najbardziej wymagających użytkowników.
        </>
      ),

      imgSrc: require("../assets/images/services/printing-lease.webp").default,
    },
    {
      title: "Serwis",
      link: "/uslugi-serwisowe",
      subtitle: "IT oraz ekspresów kawowych",
      p1: (
        <>
          Oferujemy <strong>profesjonalne usługi serwisowe</strong> obejmujące
          zarówno gwarancję, jak i pogwarancyjne wsparcie. Nasza kompleksowa
          oferta serwisowa zawiera szeroki zakres napraw, przeglądów
          technicznych oraz dostęp do <b>oryginalnych części zamiennych.</b>
        </>
      ),

      p2: (
        <>
          Oprócz szerokiej gamy usług serwisowych dla różnych urządzeń,
          <strong>
            {" "}
            specjalizujemy się również w serwisie ekspresów kawowych.
          </strong>{" "}
          Nasi serwisanci doskonale rozumieją jak istotne jest prawidłowe
          działanie ekspresu kawowego dla miłośników kawy. Dlatego oferujemy{" "}
          <strong>kompleksowe wsparcie </strong> w zakresie napraw, konserwacji
          i konserwacji profilaktycznej ekspresów kawowych.
        </>
      ),

      imgSrc: require("../assets/images/services/serwis.webp").default,
    },

    // Add more slide objects here
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide > 0
        ? (prevSlide - 1) % slides.length
        : ((prevSlide - slides.length + 1) * -1) % slides.length
    );
  };

  const handlMoveSlide = (index) => {
    setCurrentSlide(index);
  };

  const startSlideInterval = () => {
    const newIntervalId = setInterval(() => {
      handleNextSlide();
    }, 10000);
    setIntervalId(newIntervalId);
  };

  const stopSlideInterval = () => {
    clearInterval(intervalId);
    setIntervalId(null);
  };

  useEffect(() => {
    startSlideInterval();
    setServiceBg(slides.imgSrc);

    return () => {
      clearInterval(intervalId);
      setIntervalId(null);
    };
  }, []);

  return (
    <div className="services-container">
      <div className="services txt">
        <div className="services txt-container">
          <h4>Oferta</h4>
          <h2>
            Świadczymy usługi
            <br /> <strong> dla biznesu, instytucji, edukacji</strong>
          </h2>
        </div>
        <p className="headerTxt">
          Jesteśmy firmą z pasją do innowacji i technologii, gotową dostarczyć
          zaawansowane rozwiązania, które pomogą Ci osiągnąć sukces w
          dzisiejszym konkurencyjnym świecie. Nasza oferta to gwarancja
          skuteczności, efektywności i rozwoju.
        </p>
      </div>
      <div id="servicesSliderReact" className="elements-list">
        <div className="slider-slides-cnt">
          {slides.map((slide, index) => (
            <article
              onMouseOver={stopSlideInterval}
              onMouseLeave={startSlideInterval}
              onTouchStart={stopSlideInterval}
              onTouchEnd={startSlideInterval}
              key={index}
              className={`element slider-slide ${
                index === currentSlide ? "slider-slide-active" : ""
              }`}
            >
              <StaticImage
                className="dotsImg"
                src="../assets/images/dots.svg"
                alt=""
              />
              <StaticImage
                className="beltsImg"
                src="../assets/images/paski.svg"
                alt=""
              />
              <img
                className="desktopImg"
                style={{
                  display: index === currentSlide ? "flex" : "none",
                }}
                src={slide.imgSrc}
              />
              <div
                className="servicesSlider-txt-container"
                style={{
                  background: `   linear-gradient(
                  rgba(0, 0, 0, 0.75), 
                  rgba(0, 0, 0, 0.75)
                ), url(${slide.imgSrc}) no-repeat center center / cover`,
                }}
              >
                <h2>{slide.title}</h2>
                <span>{slide.subtitle}</span>
                <p>{slide.p1}</p>
                <p>{slide.p2}</p>
                <p>{slide.p3}</p>
                <Link to={slide.link}>
                  <button
                    onMouseOver={stopSlideInterval}
                    onMouseLeave={startSlideInterval}
                    className="btn-red"
                  >
                    Dowiedz się więcej
                  </button>
                </Link>
              </div>
            </article>
          ))}
        </div>
        <div className="slider-nav">
          <button
            onClick={handlePrevSlide}
            onMouseOver={stopSlideInterval}
            onMouseLeave={startSlideInterval}
            className="slider-button slider-button-prev"
          ></button>
          <button
            onClick={handleNextSlide}
            onMouseOver={stopSlideInterval}
            onMouseLeave={startSlideInterval}
            className="slider-button slider-button-next"
          ></button>
        </div>
        <ul className="slider-pagination">
          {slides.map((slide, index) => (
            <li
              key={index}
              className={`slider-pagination-element ${
                index === currentSlide ? "slider-pagination-element-active" : ""
              }`}
            >
              <button
                key={index}
                onClick={() => handlMoveSlide(index)}
                className="slider-pagination-button"
              ></button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
