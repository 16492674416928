import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

export const AboutUs = () => {
  return (
    <div className="aboutUs-container">
      <div className="imgContainer">
        <StaticImage src="../assets/images/about/abUs.jpg" />
        <div className="square"></div>
      </div>

      <div className="aboutUs txt">
        <div id="about" className="anchor" />
        <div className="aboutUs txt-container">
          <h4>O nas</h4>
          <h2>
            Poznaj swojego <br /> <strong>Partnera IT</strong>
          </h2>
        </div>
        <p className="headerTxt">
          Działamy od 1996 roku i robimy to, co potrafimy najlepiej.
          Projektujemy i dostarczamy rozwiązania informatyczne, które są „szyte
          na miarę.” Kompleksowe, spersonalizowane i adekwatne do oczekiwań.
        </p>
        <p className="headerTxt">
          Dzięki szerokiej ofercie sprzętu informatycznego dostępnego w dużej
          mierze od ręki oraz gamie usług dodatkowych, zdobyliśmy zaufanie
          naszych Partnerów, zostając liderem rynku IT we wschodniej Polsce.
          Naszą ofertę kierujemy do klientów indywidualnych, biznesowych i
          instytucjonalnych.{" "}
        </p>
        <Link to="/o-nas">
          <button className="btn-red"> Dowiedz się więcej</button>
        </Link>
      </div>
    </div>
  );
};
