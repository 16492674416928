import React from "react";

export const Logos = () => {
  return (
    <div className="logos-container">
      <div className="logos txt">
        <div className="logos txt-container">
          <h4>Partnerzy</h4>
          <h2>
            Jesteśmy oficjalnym <br /> <strong>Partnerem</strong>
          </h2>
        </div>
        <p className="headerTxt">
          Działamy na rynku od ponad 26 lat! Dzięki współpracy z liderami branży
          informatycznej masz gwarancje sprzętu oraz umiejętności naszych
          specjalistów.
        </p>
      </div>
      <div className="pageslider">
        <ul className="slides">
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Acer_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Ariete_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Asus_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Brother_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_jura_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_msi_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Mpm_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_PockeBook_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Samsung_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Salvatti_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Canon_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Brother_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Acer_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Ariete_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Asus_color.svg")
                  .default
              }
              alt=""
            />
          </li>

          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Brother_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_jura_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_msi_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Mpm_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_PockeBook_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Samsung_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Salvatti_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Canon_color.svg")
                  .default
              }
              alt=""
            />
          </li>
          <li>
            <img
              src={
                require("../assets/images/logos/logo_main_page_Brother_color.svg")
                  .default
              }
              alt=""
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
