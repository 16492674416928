import React from "react";

export function Advantages(props) {
  const { headerTitle, headerSubtitle, headerTxt, advantageElements } = props;

  return (
    <div className="advantages-container">
      <div className="advantages txt">
        <div className="advantages txt-container">
          <h4>{headerTitle}</h4>
          <h2>{headerSubtitle}</h2>
        </div>
        <p className="headerTxt">{headerTxt}</p>
      </div>
      <div className="advantages-container-wrapper">
        <div className="advantages-container-wrapper-box">
          {advantageElements.map((element, index) => (
            <div key={index} className="advantages-container-wrapper-element">
              <img
                className="advantages-img"
                alt={element.alt}
                src={element.imgSrc}
              />
              <h3>{element.contentTitle}</h3>
              <p>{element.contentTxt}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
